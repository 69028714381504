import * as THREE from 'three'
import Experience from './Experience.js'

export default class Environment {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    setTimeout(() => {
      this.setEnvironmentMapHDRI();
    })
  }

  setEnvironmentMapHDRI() {
    this.resources.items.studioTexture.mapping = THREE.EquirectangularReflectionMapping;
    this.scene.environment = this.resources.items.studioTexture;
  }
}
