import gsap from 'gsap';
import Experience from '../Experience.js'
// import EventEmitter from '../Utils/EventEmitter.js'
import SplitText from "gsap/SplitText";

export default class Loader {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    // Register gsap plugins
    gsap.registerPlugin(SplitText)

    // Set variables
    this.assetsLoaded = false;

    // init
    this.setAnimationLoader();

    // Check if all assets are loaded
    this.resources.on('ready', () => {
      this.assetsLoaded = true;

      // Disable scrolling initially to let the hero animations finish
      document.body.style.setProperty('--body-scrollwidth', `${window.innerWidth - document.body.clientWidth}px`);
      document.body.style.overflow = 'hidden'
    });

    if (this.resources.allAssetsLoaded)  {
      this.assetsLoaded = true;
    }
  }

  setAnimationLoader() {
    const loaderSplitTitle = new SplitText('.loader__title', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });

    let loaderTimeline = gsap.timeline({
      delay: 1.50,
      repeat: -1.00,
      repeatDelay: 1.00,
      onRepeat: () => {
        if (this.assetsLoaded) {
          loaderTimeline.pause();
          this.setAnimationLoaderOut();
        }
      }
    });

    loaderTimeline.from(loaderSplitTitle.words, {
      y: '100%',
      duration: 0.80,
      ease: "power4.out",
      stagger: 0.08,
      onStart: () => {
        document.querySelector('.loader__title').style.opacity = 1;
      }
    });

    loaderTimeline.to(loaderSplitTitle.words, {
      y: '100%',
      duration: 0.80,
      ease: "power4.in",
      stagger: 0.08,
      delay: 0.10,
    });
  }

  setAnimationLoaderOut() {
    this.resources.trigger('loaderOut');

    gsap.fromTo('.loader', {
      opacity: 1
    }, {
      opacity: 0.00,
      duration: 3.50,
      onComplete: (element) => {
        document.querySelector('.loader').remove();
      }
    })
  }
}
