import gsap from 'gsap';
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";

export default class Collection {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(DrawSVGPlugin)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // init
    this.setAnimationCollectionIn()
    this.setAnimationCollectionBlur()
    this.setAnimationCollectionOut()
  }

  setAnimationCollectionIn() {
    const collectionInTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-content__collection-content-in',
        scrub: false,
        toggleActions: "play none none reverse",
      },
    });

    const collectionSplitTitle = new SplitText('.collection__title', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });
    const collectionSplitText = new SplitText('.collection__text', {
      type: 'lines',
      linesClass: 'line'
    });

    new SplitText('.collection__text', {
      linesClass: 'lineParent'
    });

    // Animate in title
    collectionInTimeline.from(collectionSplitTitle.words, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
      onComplete:() => {
        document.querySelector('.overal-wrapper').style.zIndex = 0;
        document.querySelector('.timeline-content-fixed').style.zIndex = 5;
        document.querySelector('.collection').style.zIndex = 5;
      },
      onReverseComplete:() => {
        document.querySelector('.overal-wrapper').style.zIndex = 5;
        document.querySelector('.timeline-content-fixed').style.zIndex = 4;
        document.querySelector('.collection').style.zIndex = 0;
      }
    });

    // Animate in intro
    collectionInTimeline.from(collectionSplitText.lines, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    }, '>-0.6');

    // Animate in button
    collectionInTimeline.fromTo('.order__btn', {
      opacity: 0,
      y: '24px',
      filter: "blur(20px)",
    },{
      opacity: 1,
      y: '0',
      duration: 1,
      ease: "power2.out",
      filter: "blur(0px)",
    }, '>-0.8');

    // Animate in intro
    collectionInTimeline.to('.sticky-cta', {
      opacity: 0,
      duration: 1,
      ease: "power2.out",
    }, '>-0.8');

  }

  setAnimationCollectionBlur() {

  }

  setAnimationCollectionOut() {
      gsap.fromTo('.footer__background', {
        opacity: 0,
        y: '-160px',
        // delay: 0.5,
        scale: 1.4,
      },{
        scrollTrigger: {
          trigger: '.timeline-jersey__collection',
          scrub: true,
        },
        opacity: 0.9,
        y: 0,
        scale: 1,
        ease: "power1.out",
      });
  }
}
