export default class IsTouchDevice {
    constructor() {}

    check() {
        return window.matchMedia("(pointer: coarse)").matches ||
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0;
    }
}
