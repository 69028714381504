import Experience from './Experience/Experience.js'

let initialized = false;

const init = () => {
    initialized = true;
    new Experience(document.querySelector('canvas.webgl'));
}

document.addEventListener('age-gate-set', () => {
    init();
});

if (document.querySelector('[lang="en"]')) {
    init();
}

if (document.querySelector('[lang="nl"]')) {
    setTimeout(() => {
        if (initialized) {
            return;
        }

        init();
    }, 5000);
}
