import gsap from 'gsap';
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";

export default class Icon {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(DrawSVGPlugin)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // init
    this.setAnimationIcon()
  }

  setAnimationIcon() {
    this.timelineIcon = gsap.timeline({
      defaults: {
        ease: "power1.inOut",
        // smoothChildTiming: true,
      }
    });

    this.animateElement(".outer-el", 0, "true");
    this.animateElement(".fdl-el-1", 0, "0% 33%");
    this.animateElement(".fdl-el-2", "33% 33%", "33% 66%");
    this.animateElement(".fdl-el-3", "66% 66%", "66% 100%");

    // Fade out icon
    this.timelineIcon.fromTo('.icon', {
      opacity: 1,
      scale: 1,
      filter: "blur(0px)",
    }, {
      scrollTrigger: {
        trigger: '.timeline-content__icon-cross',
      },
      scale: 0.6,
      opacity: 0,
      filter: "blur(20px)",
    })
  }

  animateElement(target, initialDrawSVGValue, drawSVGValue) {
    // Set the initial value
    gsap.set(target, {drawSVG: initialDrawSVGValue});

    // Animate the element
    gsap.to(target, {
      scrollTrigger: {
        trigger: '.timeline-content__icon-in',
      },
      drawSVG: drawSVGValue,
      ease: "power1.inOut"
    });
  }
}
