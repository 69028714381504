import * as THREE from 'three'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import Experience from '../Experience.js'

export default class Shirt {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.setShirtOpacity = this.experience.setShirtOpacity.setShirtOpacity
    this.resource = this.resources.items.jerseyModel
    this.mm = this.experience.mm

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // Set up shirt opacity
    this.shirtOpacity = {
      opacity: 1
    };

    // Init model
    this.setModel()

    // Wait for resources
    if (this.resources.allAssetsLoaded)  {
      this.resources.on('loaderOut', () => {
        this.setTimelineShirt();
        this.setTimelineOpacity();
      });
    }
  }

  /**
   * Set the model of the shirt
   */
  setModel() {
    this.model = this.resource.scene
    this.scene.add(this.model)
  }

  /**
   * Set the opacity of the shirt
   */
  setTimelineOpacity() {

    // Create a GSAP timeline
    this.timelineOpacity = gsap.timeline({
      defaults: {
        ease: "power1.inOut",
      }
    });

    // Hide rotating CTA button initially
    gsap.set('.order-now-cta', {opacity: 0});
   }

  setTimelineShirt() {
    // Create a GSAP timeline
    this.timelineShirt = gsap.timeline({
      defaults: {
        immediateRender: false,
        ease: "power1.inOut",
        // smoothChildTiming: true,
      }
    });

    /**
     * DESKTOP ONLY ANIMATIONS
     */
    this.mm.add("(min-width: 768px)", () => {

      // Move in jersey (Hero section)
      this.timelineShirt.fromTo(this.model.position, {
        x: 0.00,
        y: 0.05,
        z: 0.50,
      }, {
        y: 0.00,
        z: -0.40,
        duration: 2.5,
        ease: "power2.out",
      });

      // Move jersey out (Hero section)
      this.mm.add("(min-width: 768px)", () => {
        this.timelineShirt.to(this.model.position, {
          scrollTrigger: {
            trigger: ".timeline-jersey__hero-out",
          },
          x: 0.00,
          z: -0.90,
          y: -0.05,
        });
      });

      // Zoom in jersey (Discover section) (
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__zoom",
        },
        x: 0.40,
        z: 1.00,
      });

      // Rotate jersey (Discover section) (
      this.timelineShirt.to(this.model.rotation, {
        scrollTrigger: {
          trigger: ".timeline-jersey__zoom",
        },
        y: Math.PI / -4.00,
      });

      // Blur jersey (Discover section) (
      this.timelineShirt.to('.webgl', {
        scrollTrigger: {
          trigger: '.timeline-content__discover-grid',
          scrub: true,
          start: "top bottom",
        },
        opacity: 0.6,
        filter: 'blur(40px)',
      });

      // Move jersey (Discover section) (
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__discover-end",
        },
        z: -0.40,
        x: 0.00,
        y: -0.05,
      });

      // Un-blur jersey (Discover section) (
      this.timelineShirt.to('.webgl', {
        scrollTrigger: {
          trigger: '.timeline-jersey__discover-end',
          scrub: true,
          end: "top top"
        },
        opacity: 1,
        filter: 'blur(0px)',
      });

      // Scale in jersey (Collection section) (
      this.timelineShirt.fromTo(this.model.position, {
        z: -2.00,
      }, {
        scrollTrigger: {
          trigger: ".timeline-jersey__show",
        },
        z: -1.00,
      });

      // Scale down jersey (Collection section) (
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__collection",
        },
        z: -1.50,
      });
    });

    /**
     * MOBILE ONLY ANIMATIONS
     */
    this.mm.add("(max-width: 767px)", () => {
      // Move in jersey (Hero section)
      this.timelineShirt.fromTo(this.model.position, {
        x: 0.00,
        y: 0.05,
        z: 0.20,
      }, {
        y: -0.05,
        z: -1.00,
        duration: 2.5,
        ease: "power2.out",
      });

      // Move out jersey (Hero section)
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__hero-out",
        },
        x: 0.00,
        z: -2.00,
        y: -0.05,
      });

      // Zoom in jersey (Discover section)
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__zoom",
        },
        x: 0.20,
        z: 0.40,
      });

      // Blur jersey (Discover section)
      this.timelineShirt.to('.webgl', {
        scrollTrigger: {
          trigger: '.timeline-jersey__zoom',
          scrub: true,
          start: "center bottom",
        },
        opacity: 0.6,
        filter: 'blur(40px)',
      });

      // Move jersey (Discover section)
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__discover-end",
        },
        y: 0.20,
        x: 0.00,
        z: -2.00,
      });

      // Un-blur jersey (Discover section)
      this.timelineShirt.to('.webgl', {
        scrollTrigger: {
          trigger: '.timeline-jersey__discover-end',
          scrub: true,
          end: "center bottom",
        },
        opacity: 1,
        filter: 'blur(0px)',
      });

      // Scale in jersey (Collection section) (
      this.timelineShirt.fromTo(this.model.position, {
        z: -3.00,
        y: -0.05,
      }, {
        scrollTrigger: {
          trigger: ".timeline-jersey__show",
        },
        z: -2.00,
      });

      // Scale down jersey (Collection section)
      this.timelineShirt.to(this.model.position, {
        scrollTrigger: {
          trigger: ".timeline-jersey__collection",
        },
        z: -2.50,
      });
    });

    /**
     * GLOBAL ANIMATIONS
     */
    // Rotate in jersey (Hero section)
    gsap.fromTo(this.model.rotation, {
      x: Math.PI / 48.00, // Tilt forward by 30 degrees (π/6 radians).
    }, {
      x: Math.PI / 24.00, // Tilt forward by 30 degrees (π/6 radians).
      duration: 1.4,
      ease: "power1.out",
    });

    // Rotate jersey (Discover section)
    this.timelineShirt.to(this.model.rotation, {
      scrollTrigger: {
        trigger: ".timeline-jersey__zoom",
      },
      y: Math.PI / -4.00,
    });

    // Move out jersey (Discover section)
    this.timelineShirt.to(this.model.position, {
      scrollTrigger: {
        trigger: ".timeline-jersey__discover-move",
        scrub: true,
      },
      y: -0.20,
    });

    // Rotate jersey behind grid (Discover section)
    this.timelineShirt.to(this.model.rotation, {
      scrollTrigger: {
        trigger: ".timeline-jersey__discover-move",
        scrub: true,
      },
      y: Math.PI / -9,
    });

    // Blur in rotating CTA button (Discover section)
    gsap.fromTo('.order-now-cta', {
      filter: 'blur(20px)',
      opacity: 0,
    },{
      scrollTrigger: {
        trigger: ".timeline-jersey__discover-cta-in",
        scrub: false,
        toggleActions: "play none none reverse",
        start: "top center",
        end: "bottom center",
      },
      filter: 'blur(0px)',
      opacity: 1,
      ease: "power1.inOut",
      onComplete:() => {
        document.querySelector('.order-now-cta').style.zIndex = 5;
      },
      onReverseComplete:() => {
        document.querySelector('.order-now-cta').style.zIndex = 0;
      }
    });

    // Blur out rotating CTA button (Discover section)
    gsap.fromTo('.order-now-cta', {
      opacity: 1,
      filter: 'blur(0px)',
    },{
      scrollTrigger: {
        trigger: '.timeline-jersey__discover-cta-out',
        scrub: false,
        toggleActions: "play none none reverse",
        start: "top center",
        end: "bottom center",
      },
      filter: 'blur(20px)',
      opacity: 0,
      ease: "power1.inOut"
    });

    // Rotate out jersey (Discover section)
    this.timelineShirt.to(this.model.rotation, {
      scrollTrigger: {
        trigger: ".timeline-jersey__discover-end",
      },
      y: Math.PI * 1.5
    });

    // Hide jersey behind video - set up blur for later animation (Video section)
    this.timelineShirt.to('.webgl', {
      scrollTrigger: {
        trigger: ".timeline-jersey__video-hide",
        scrub: false,
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      opacity: 0,
      filter: "blur(40px)",
    });

    // Un-blur jersey over icon (Icon section)
    this.timelineShirt.to('.webgl', {
      scrollTrigger: {
        trigger: ".timeline-jersey__show",
      },
      filter: "blur(0px)",
      opacity: 1,
    });

    // Rotate jersey - set up for later animation (Icon section)
    this.timelineShirt.fromTo(this.model.rotation, {
      y: Math.PI / 1
    }, {
      scrollTrigger: {
        trigger: ".timeline-jersey__show",
      },
      y: Math.PI / 1
    });

    // Rotate jersey (Collection section)
    this.timelineShirt.to(this.model.rotation, {
      scrollTrigger: {
        trigger: ".timeline-jersey__collection",
      },
      y: Math.PI * 2.2
    });

    // Fade jersey (Collection section)
    this.timelineShirt.to('.webgl', {
      scrollTrigger: {
        trigger: '.timeline-jersey__collection',
        start: 'center center'
      },
      opacity: 0.1,
    });
  }
}
