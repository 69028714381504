import * as THREE from 'three'
// import gsap from 'gsap';
import gsap from "gsap";
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";

export default class Hero {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.resource = this.resources.items.jerseyModel

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(DrawSVGPlugin)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      // scrub: true,
    })

    // init
   this.setAnimationVideoIn();

    gsap.set(".video__deco-icon-1__icon", {drawSVG: 0});
  }

  setAnimationVideoIn() {
    const videoSplitTitle = new SplitText('.video__title', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });

      let videoInTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.timeline-content__video',
          start: "top top",
          end: "bottom bottom",
          // scrub: true,/
          scrub: false,
          toggleActions: "play none none reverse"
        }
      });

    videoInTimeline.from(videoSplitTitle.words, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    });

    document.querySelectorAll('.video__paragraph').forEach((par) => {
      const videoSplitText = new SplitText(par, {
        type: 'lines',
        linesClass: 'line'
      });
      new SplitText(par, {
        linesClass: 'lineParent'
      });

      videoInTimeline.from(videoSplitText.lines, {
        y: '100%',
        stagger: 0.05,
        duration: 0.8,
        ease: "power4.out",
      }, '>-0.8');
    });

    videoInTimeline.from('.video__btn', {
      opacity: 0,
      y: '24px',
      duration: .8,
      ease: "power2.out",
      filter: "blur(20px)",
    }, '>-0.8');

    videoInTimeline.to(".video__deco-icon-1__icon", {
      drawSVG: true,
      duration: 0.9,
      ease: "power1.inOut"
    }, '>-0.9');
  }
}
