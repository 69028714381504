import Experience from '../Experience.js'
import Loader from './Loader.js'
import ScrollIndicator from './ScrollIndicator.js'
import Shirt from './Shirt.js'
import Hero from './Hero.js'
import Activation from './Activation.js'
import Discover from './Discover.js'
import Video from './Video.js'
import Popup from './Popup.js'
import Inspiration from './Inspiration.js'
import Icon from './Icon.js'
import Collection from './Collection.js'
import Order from './Order.js'
import Footer from './Footer.js'

import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources;

        gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

        // Perform a media query check and create the scroll smoother based on it
        const createScrollSmoother = () => {
            if (this.experience.isTouchDevice.check()) {
                if (this.smoother) this.smoother.kill();
            } else {
                if (this.smoother) this.smoother.kill();
                this.smoother = ScrollSmoother.create({
                    smooth: 4,
                    effects: true,
                });
            }
        };

        // Run the initial check when the script loads
        createScrollSmoother();

        // Loader
        this.loader = new Loader()

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            setTimeout(function() {
                this.scrollindicator = new ScrollIndicator()
                this.shirt = new Shirt()
                this.hero = new Hero()
                this.discover = new Discover()
                this.video = new Video()
                this.popup = new Popup()
                this.inspiration = new Inspiration()
                this.icon = new Icon()
                this.collection = new Collection()
                this.order = new Order()
                this.footer = new Footer()

                if (document.body.classList.contains('activation-active')) {
                    this.activation = new Activation()
                }

                window.scrollTo(0, 0);
            }, 500);
        })
    }
}
