import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Experience from '../Experience.js'

export default class Footer {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: '.footer__outer',
      start: 'top bottom',
      end: 'bottom bottom',
      scrub: true,
      onUpdate: (self) => {
        const moveUp = document.querySelector('.footer__outer').offsetHeight * self.progress;
        gsap.to('.timeline-content-fixed', { y: -moveUp, ease: 'none', duration: 0.01 });
        gsap.to('.footer__background', { y: -moveUp, z: 123, ease: 'none', duration: 0.01 });
        gsap.to('.webgl', { y: -moveUp, ease: 'none', duration: 0.01 });
      }
    });
  }
}
