export default class Popup {
  constructor() {
    this.init();
  }

  init() {
    this.popup = document.querySelector('.js-popup');
    this.popupOpen = document.querySelectorAll('.js-toggle-popup');
    this.iframe = document.querySelector('.js-popup iframe');

    const iframeUrl = this.iframe.src || '';

    if (!this.popupOpen.length || !this.popup) {
      return;
    }

    this.popupOpen.forEach((button) => {
      button.addEventListener('click', () => {
        const popupActiveClassName = 'popup-active';

        if (document.body.classList.contains(popupActiveClassName)) {
          if (!this.iframe) {
            return;
          }

          this.iframe.src = iframeUrl;
        }

        document.body.classList.toggle('popup-active');
      });
    });
  }
}
