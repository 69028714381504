export default class SetShirtOpacity {
  constructor() {}

  setShirtOpacity(object, opacity){
    object.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;

        if (material) {
          material.transparent = true; // Enable transparency
          material.opacity = opacity; // Set the desired opacity value
          material.needsUpdate = true; // Update the material
        }
      }
    });
  }
}
