export default [
    {
        name: 'studioTexture',
        type: 'hdr',
        path: 'textures/studio-5.hdr'
    },
    {
        name: 'jerseyModel',
        type: 'gltfModel',
        path: 'models/new-shirt-2/tvl-jersey.gltf'
    }
]
