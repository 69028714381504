import * as THREE from 'three'
// import gsap from 'gsap';
import gsap from "gsap";
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';

const openOverlay = (campaign) => {
  if (!campaign) {
    return;
  }

  const campaignPopup = document.querySelector(`.js-activation-popup[data-campaign="${campaign}"]`);

  if (!campaignPopup) {
    return;
  }

  document.body.classList.add('activation-popup-active');
  campaignPopup.style.opacity = 1;
  campaignPopup.style.visibility = 'visible';
};

const closeOverlay = () => {
  document.body.classList.remove('activation-popup-active');

  document.querySelectorAll('.js-activation-popup').forEach((popup) => {
    popup.style.opacity = 0;
    popup.style.visibility = 'hidden';
  });
};

export function toggleActivationOverlay() {
  // Visible > Close
  if (document.body.classList.contains('activation-popup-active')) {
    closeOverlay();
    return;
  }

  // Find best match
  // Betting = true (only NL, show BetCity popup)
  const params = new URLSearchParams(window.location.search);
  const amsterdamTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Amsterdam' });
  const amsterdamDate = new Date(amsterdamTime);
  const maximumTime = new Date('2024-07-15T00:00:00+0200');

  if (amsterdamDate < maximumTime && document.querySelector('[data-showbetting="true"]') && params.has('target') && params.get('target') === 'bc') {
    openOverlay('betcity');
  }
}

export default class Activation {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = this.experience.time
    this.debug = this.experience.debug
    this.resource = this.resources.items.jerseyModel
    this.world = this.experience.world

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);

    this.popups = document.querySelectorAll('.js-activation-popup');
    this.popupOpen = document.querySelectorAll('.js-toggle-activation-popup');

    if (!this.popupOpen.length || !this.popups.length) {
      return;
    }

    this.popupOpen.forEach((button) => {
      button.addEventListener('click', () => {
        toggleActivationOverlay();
      });
    });
  }
}
