import Experience from '../Experience.js';

export default class ScrollIndicator {
  constructor() {
    this.experience = new Experience()
    this.world = this.experience.world;

    document.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop;
      this.scrollIndicator();
      this.sectionScroll();
    });
  }

  sectionScroll() {
    const items = document.querySelectorAll('.global-pagination__item');
    const middleScreen = this.scrollTop + (window.innerHeight / 2);

    items.forEach((item, index) => {
      const offset = document.querySelector(`.timeline-content__${item.id}`).offsetTop + 10;

      item.addEventListener('click', () => {
        this.world.smoother.scrollTo(offset, 5);
      });

      if (offset <= middleScreen) {
        item.classList.remove('global-pagination__item--passed-state');
        item.classList.add('global-pagination__item--current-state');
        item.classList.remove('global-pagination__item--upcoming-state');

        if (index > 0) {
          const previousItem = items[index - 1];
          previousItem.classList.add('global-pagination__item--passed-state');
          previousItem.classList.remove('global-pagination__item--current-state');
          previousItem.classList.remove('global-pagination__item--upcoming-state');
        }
      } else {
        item.classList.remove('global-pagination__item--passed-state');
        item.classList.remove('global-pagination__item--current-state');
        item.classList.add('global-pagination__item--upcoming-state');
      }
    });
  }

  scrollIndicator() {
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollFraction = this.scrollTop / docHeight;

    // Total length of the square's perimeter
    const squarePerimeter = 360; // Assuming the square has a perimeter of 360 units
    const offset = squarePerimeter - (squarePerimeter * scrollFraction);

    const progressBar = document.querySelector('.scroll-indicator__square__progress');
    progressBar.style.strokeDashoffset = offset;
  }
}
