import GUI from 'lil-gui'

export default class Screenheight
{
  constructor() {
    this.setVh();
  }

  setVh() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }
}
