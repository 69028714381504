import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import SetShirtOpacity from './Utils/SetShirtOpacity.js'
import Screenheight from './Utils/Screenheight.js';
import IsTouchDevice from './Utils/IsTouchDevice.js';
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'
import Environment from './Environment.js';
import sources from './sources.js'
import gsap from "gsap";

let instance = null
var scrollPosition = null;

export default class Experience {
  constructor(_canvas) {
    // Singleton
    if (instance) {
      return instance
    }
    instance = this

    // Global access
    window.experience = this

    // Options
    this.canvas = _canvas

    // Setup
    this.debug = new Debug()
    this.setShirtOpacity = new SetShirtOpacity()
    this.screenheight = new Screenheight()
    this.isTouchDevice = new IsTouchDevice()
    this.sizes = new Sizes()
    this.time = new Time()
    this.scene = new THREE.Scene()
    this.resources = new Resources(sources)
    this.camera = new Camera()
    this.renderer = new Renderer()
    this.world = new World()
    this.mm = gsap.matchMedia()

    this.resources.on('ready', () => {
      this.environment = new Environment()
    })

    // Change header styling after 100vh has been scrolled past
    window.addEventListener('scroll', function() {
      var element = document.getElementsByClassName('header')[0];
      scrollPosition = window.scrollY;

      if (scrollPosition >= window.innerHeight) { // If scrolled beyond 100vh
        document.body.classList.add('expanded-header'); // Add the desired class
      } else {
        document.body.classList.remove('expanded-header'); // Remove the class if scrolled back up
      }
    });

    // Resize event
    this.sizes.on('resize', () => {
      this.resize()
      scrollPosition = window.scrollY;
    })

    // Time tick event
    this.time.on('tick', () => {
      this.update()
    })
  }

  resize() {
    this.camera.resize()
    this.renderer.resize()
  }

  update() {
    this.renderer.update()
  }

  destroy() {
    this.sizes.off('resize')
    this.time.off('tick')

    // Traverse the whole scene
    this.scene.traverse((child) => {
      // Test if it's a mesh
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose()

        // Loop through the material properties
        for (const key in child.material) {
          const value = child.material[key]

          // Test if there is a dispose function
          if (value && typeof value.dispose === 'function') {
            value.dispose()
          }
        }
      }
    })

    this.camera.controls.dispose()
    this.renderer.instance.dispose()

    if (this.debug.active)
      this.debug.ui.destroy()
  }
}
