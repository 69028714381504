import gsap from 'gsap';
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";

export default class Order {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // init
    this.setAnimationOrderIn()
  }

  setAnimationOrderIn() {

  }
}
