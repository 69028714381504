import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import Experience from '../Experience.js';
import { toggleActivationOverlay } from '../World/Activation.js';

export default class Hero {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.setShirtOpacity = this.experience.setShirtOpacity.setShirtOpacity
    this.resource = this.resources.items.jerseyModel

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    gsap.set('.hero__backdrop', { opacity: 0 });

    this.resources.on('loaderOut', () => {
      this.setAnimationHeroIn()
      this.setAnimationHeroOut()
    });
  }

  // Enable scrolling again
  enableScrolling() {
    document.body.style.overflow = 'auto';
    toggleActivationOverlay()

    // Normalize scroll
    // ScrollTrigger.normalizeScroll(true);
  }

  setAnimationHeroIn() {
    // Create the timeline
    const heroInTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-content__hero',
        scrub: false,
        onLeave: () => {
          heroInTimeline.progress(1);
        },
      },
      delay: 2,
      ease: "power1.inOut",
    });

    this.heroPreSplit = new SplitText('.hero__title__pre', {
      type: 'chars, words, lines',
      linesClass: 'lineParent'
    });
    this.heroMainSplit = new SplitText('.hero__title__main', {
      type: 'chars, words, lines',
      linesClass: 'lineParent'
    });
    this.heroSubSplit = new SplitText('.hero__title__sub', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });

    // Animate in (pillars) background (Hero section)
    gsap.fromTo('.hero__background', {
      opacity: 0,
      y: '-10%',
      scale: 1.4,
    }, {
      opacity: 0.6,
      y: 0,
      duration: 2.5,
      scale: 1,
      ease: "power4.out",
      immediateRender: true,
    });

    // Animate in pre-title. While doing so, fade in the gradient over the jersey (Hero section)
    gsap.from(this.heroPreSplit.chars, {
      y: '100%',
      stagger: 0.05,
      duration: 1,
      ease: "power4.out",
      delay: 1.8,
      onStart() {
        gsap.to('.hero__backdrop', {
          opacity: 1.0,
          duration: 1.0,
          ease: "power1.out",
        });
      }
    });

    // Animate in title (Hero section)
    gsap.from(this.heroMainSplit.chars, {
      y: '100%',
      stagger: 0.05,
      duration: 1,
      ease: "power4.out",
      delay: 1.6,
    });

    // Animate in paragraph (Hero section)
    gsap.from(this.heroSubSplit.words, {
      y: '100%',
      stagger: 0.05,
      duration: 0.6,
      ease: "power4.out",
      delay: 2.0,
    });

    // Animate in title and enable scrolling after doing so (Hero section)
    gsap.from('.hero__btn', {
      opacity: 0,
      y: '24px',
      duration: .8,
      ease: "power2.out",
      delay: 2.6,
      filter: "blur(20px)",
      onComplete: this.enableScrolling
    });

    // Blur in the sticky CTA (Hero section)
    gsap.fromTo('.sticky-cta', {
      opacity: 0,
      filter: "blur(20px)",
    }, {
      opacity: 1,
      duration: .8,
      delay: 2.0,
      filter: "blur(0px)",
      ease: "power2.out",
    });
  }

  setAnimationHeroOut() {
    const heroOutTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-content__hero-out',
        scrub: true,
        toggleActions: 'play none reverse none',
      },
    });

    if (!this.heroMainSplit) {
      return;
    }

    // Scroll out all hero section content (Hero section)
    heroOutTimeline.to('.hero__inner', {
      y: '-640px',
      opacity: 0,
      ease: "power1.inOut",
      filter: 'blur(20px)'
    });

    // Animate out (pillars) background (Hero section)
    gsap.fromTo(".hero__background", {
      opacity: 0.6,
      y: 0,
      scale: 1,
    }, {
      scrollTrigger: {
        trigger: '.timeline-bg__hero-out',
        scrub: true,
      },
      scale: 1.2,
      opacity: 0,
      y: '10%',
      ease: "power1.inOut",
    });

    // Fade out gradient over the jersey (Hero section)
    heroOutTimeline.to(".hero__backdrop", {
      opacity: 0,
      ease: "power1.inOut",
    });
  }
}
