import gsap from 'gsap';
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";

export default class Inspiration {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.world = this.experience.world
    this.mm = this.experience.mm

    this.speeds = [1, 0.95, 1.05, 1];

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(DrawSVGPlugin)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // init
    this.setAnimationInspirationOut();
    this.setAnimationInspirationIn();
    this.setAnimationInspirationBlur();
    this.setAnimationInspirationGrid();

    gsap.set(".inspiration__deco-icon-1__icon", {drawSVG: 0});
    gsap.set(".inspiration__deco-icon-3__icon", {drawSVG: 0});
  }

  setAnimationInspirationIn() {
    const inspirationInTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-content__inspiration-in',
        scrub: false,
        toggleActions: "play none none reverse",
      },
    });

    const inspirationSplitTitle = new SplitText('.inspiration__title', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });

    const inspirationSplitText = new SplitText('.inspiration__text', {
      type: 'lines',
      linesClass: 'line'
    });
    new SplitText('.inspiration__text', {
      linesClass: 'lineParent'
    });

    // Animate in hero background
    gsap.fromTo('.inspiration__background', {
      opacity: 0,
      y: '50%',
    }, {
      scrollTrigger: {
        trigger: '.timeline-bg__inspiration-in',
      },
      opacity: 0.3,
      y: 0,
      ease: "power1.inOut",
    });

    // Animate in title
    inspirationInTimeline.from(inspirationSplitTitle.words, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    });

    // Animate in intro
    inspirationInTimeline.from(inspirationSplitText.lines, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    }, '>-0.8');

    inspirationInTimeline.to(".inspiration__deco-icon-1__icon", {
      drawSVG: true,
      duration: 0.9,
      ease: "power1.inOut"
    }, '>-0.9');
  }

  setAnimationInspirationBlur() {
    // Animate in hero background
    gsap.to('.inspiration__content', {
      scrollTrigger: {
        trigger: '.timeline-content__inspiration-blur',
        scrub: true,
      },
      y: '-100%',
      filter: 'blur(20px)',
      opacity: 0,
      ease: "power1.inOut",
    });
  }

  setAnimationInspirationOut() {
    // Animate out background
    gsap.fromTo(".inspiration__background", {
      opacity: 0.3,
      y: 0,
      filter: "blur(0px)",
    }, {
      scrollTrigger: {
        trigger: '.timeline-bg__inspiration-out',
      },
      opacity: 0,
      filter: "blur(40px)",
      ease: "power1.inOut",
    });
  }

  setAnimationInspirationGrid() {
    document.querySelectorAll('.inspiration__grid__item').forEach((item, index) => {
      const image = item.querySelector('.inspiration__grid__image');
      gsap.fromTo(image, {
        clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
        scale: 1.4,
      },{
        scrollTrigger: {
          trigger: item,
          start: "center bottom",
          end: "center bottom-=20%",
          scrub: false,
          toggleActions: 'play none none reverse',
        },
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        scale: 1,
        ease: "power1.out",
        duration: 1.2,
        onStart: () => {
          image.style.opacity = 1;

          if (index === 0 ) {
            gsap.to(".inspiration__deco-icon-2__icon", {
              scrollTrigger: {
                scrub: false,
                scrollTrigger: item,
                toggleActions: 'play none reverse none',
              },
              drawSVG: true,
              duration: 0.9,
              ease: "power1.inOut"
            });
          }
          if (index === 2 ) {
            gsap.to(".inspiration__deco-icon-3__icon", {
              scrollTrigger: {
                scrub: false,
                scrollTrigger: item,
                toggleActions: 'play none reverse none',
              },
              drawSVG: true,
              duration: 0.9,
              ease: "power1.inOut"
            });
          }
        },
        onReverseComplete: () => {
          if (index === 0 ) {
            gsap.to(".inspiration__deco-icon-2__icon", {
              scrollTrigger: {
                scrub: false,
                scrollTrigger: item,
                toggleActions: 'play none reverse none',
              },
              drawSVG: "0% 0%",
              duration: 0.9,
              ease: "power1.inOut"
            });
          }
          if (index === 2 ) {
            gsap.to(".inspiration__deco-icon-3__icon", {
              scrollTrigger: {
                scrub: false,
                scrollTrigger: item,
                toggleActions: 'play none reverse none',
              },
              drawSVG: "0% 0%",
              duration: 0.9,
              ease: "power1.inOut"
            });
          }
        }
      });

      // Disable parallax on touch device
      if(!this.experience.isTouchDevice.check()) {
        // Simple parallax effect
        this.world.smoother.effects(image, {
          speed: this.speeds[index],
          lag: 0.5
        })
      }
    })
  }
}
