import * as THREE from 'three'
// import gsap from 'gsap';
import gsap from "gsap";
import Experience from '../Experience.js'
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from "gsap/SplitText";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";

export default class Discover {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.world = this.experience.world

    this.speeds = [1, 0.98, 0.90, 1.15, 1.05];

    // Register gsap plugins
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(DrawSVGPlugin)

    // Set up ScrollTrigger defaults
    ScrollTrigger.defaults({
      start: "top center",
      end: "bottom center",
      scrub: true,
    })

    // init
    this.setAnimationDiscoverIn();
    this.setAnimationDiscoverGrid();

    gsap.set(".discover__deco-icon-1__icon", {drawSVG: 0});
  }

  setAnimationDiscoverIn() {
    const discoverInTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.timeline-content__discover-in',
        scrub: false,
        start: "top top",
        end: "bottom bottom",
        toggleActions: "play none none reverse",
      },
    });

    const discoverSplitPre = new SplitText('.discover__pre', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });
    const discoverSplitTitle = new SplitText('.discover__title', {
      type: 'words, lines',
      linesClass: 'lineParent'
    });
    const discoverSplitIntro = new SplitText('.discover__intro', {
      type: 'lines',
      linesClass: 'line'
    });
    new SplitText('.discover__intro', {
      linesClass: 'lineParent'
    });

    // Animate in pre
    discoverInTimeline.from(discoverSplitPre.words, {
      y: '100%',
      stagger: 0.05,
      duration: 1,
      ease: "power4.out",
    });

    // Animate in title
    discoverInTimeline.from(discoverSplitTitle.words, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    }, '>-1.2');

    // Animate in intro
    discoverInTimeline.from(discoverSplitIntro.lines, {
      y: '100%',
      stagger: 0.05,
      duration: 0.8,
      ease: "power4.out",
    }, '>-0.8');

    discoverInTimeline.to(".discover__deco-icon-1__icon", {
      drawSVG: true,
      duration: 0.9,
      ease: "power1.inOut"
    }, '>-0.9');
  }

  setAnimationDiscoverGrid() {
    gsap.to('.discover__content', {
      scrollTrigger: {
        trigger: '.timeline-content__discover-grid',
        scrub: true,
        start: "top bottom",
      },
      y: '-50%',
      filter: 'blur(20px)',
      opacity: 0,
      ease: "power1.inOut",
      onStart:() => {
        document.querySelector('.overal-wrapper').style.zIndex = 5;
      },
      onReverseComplete:() => {
        document.querySelector('.overal-wrapper').style.zIndex = 0;
      }
    });

    gsap.set(".discover__deco-icon-2__icon", {drawSVG: 0});
    gsap.to(".discover__deco-icon-2__icon", {
      scrollTrigger: {
        trigger: '.timeline-content__discover-grid',
        scrub: false,
        toggleActions: "play none none reverse",
      },
      drawSVG: true,
      duration: 0.9,
      ease: "power1.inOut"
    });

    gsap.set(".discover__deco-icon-3__icon", {drawSVG: 0});
    gsap.to(".discover__deco-icon-3__icon", {
      scrollTrigger: {
        trigger: '.timeline-content__discover-grid',
        scrub: false,
        toggleActions: "play none none reverse",
        start: "bottom bottom",
      },
      drawSVG: true,
      duration: 0.9,
      ease: "power1.inOut"
    });

    document.querySelectorAll('.discover__grid__item').forEach((item, index) => {
      const image = item.querySelector('.discover__grid__image');

      gsap.fromTo(image, {
       clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
        scale: 1.4,
      },{
        scrollTrigger: {
          trigger: item,
          start: "center bottom",
          end: "center bottom-=20%",
          scrub: false,
          toggleActions: 'play none none reverse',
        },
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        scale: 1,
        ease: "power1.out",
        duration: 1.2,
        onStart: () => {
          image.style.opacity = 1;
        },
      });

      /**
       * DESKTOP ONLY ANIMATIONS
       */
      if(!this.experience.isTouchDevice.check()) {
        // simple parallax effect
        this.world.smoother.effects(image, {
          speed: this.speeds[index],
          lag: 0.5
        })
      }
    })
  }
}
